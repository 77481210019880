export default {
    translations: {
        menu: {
            home: 'Home',
            about: 'Sobre',
            support_us: 'Apoie',
            join_us: 'Faça parte',
            upcoming_events: "Próximos eventos",
            upcoming_lives: "Próximas lives",
            team: 'Time',
            social_network: 'Redes sociais',
        },
        section_home: {
            title: "Comunidade brasileira para ​quem tem interesse em ​aprender e se aprofundar ​em NodeJS",
            button: "Faça parte da comunidade",
        },
        section_about: {
            title: "Somos uma comunidade de devs para devs",
            card_1_title: "+4.000 MEMBROS ​NO MEETUP",
            card_1_text: "Estamos sempre por lá divulgando eventos online e presencial",
            card_2_title: "CURSO NODEJS ​PARA INCIANTES",
            card_2_text: "Um dos maiores cursos online que já ajudou milhares de devs a entrarem no mercado",
            card_3_title: "NODEJS DO BÁSICO ​AO AVANÇADO",
            card_3_text: "Entregues de forma gratuita para a comunidade",
        },
        section_support: {
            title: "Empresas que ao longo desses anos já apoiaram a comunidade",
            button: "Nos apoie com sua empresa",
        },
        section_voluntary: {
            title: "Venha fazer parte da comunidade",
            button_1: "Seja voluntário(a)",
            button_2: "Parcerias",
        },
        section_team: {
            title: "Time",
        },
        section_social: {
            title: "redes sociais",
        },
        section_upcoming_events: {
            title: "Próximos eventos",
        },
        section_upcoming_lives: {
            title: "Próximas lives",
        },
    }
}