import { Col, Row } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';

interface CustomWindow extends Window {
    $guild: {
        renderGuildEventList: Function;
    };
}

declare let window: CustomWindow;

export const UpcomingEventsComponent = () => {
    const [translate] = useOutletContext() as any;

    useEffect(() => {
        window.$guild.renderGuildEventList(
            document.getElementById("guild-events-upcoming"),
            "NodeBR",
            "UPCOMING"
        );
    }, []);

    return (<>
        <div id="section_upcoming_events" className="site-content padding-bottom-large min-full-height bg-primary">
            <div className="text-ovesrlay">
                <Row gutter={[16, 16]} justify={"center"}>
                    <Col xs={24} sm={18}>
                        <Row gutter={[16, 16]} className='section-services' justify={'center'}>
                            <Col span={24} className='services-title'>
                                <div className='center-content'>
                                    {translate.t("section_upcoming_events.title")}
                                </div>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16, 16]} justify={'center'}>
                                    <Col xs={24} md={16}>
                                        <div id="guild-events-upcoming"></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    </>)
}