export default {
    translations: {
        menu: {
            home: 'Home',
            about: 'About',
            support_us: 'Support Us',
            join_us: 'Join Us',
            upcoming_events: "Upcoming events",
            upcoming_lives: "Upcoming Lives",
            team: 'Team',
            social_network: 'Social Media',
        },
        section_home: {
            title: "Brazilian community for those interested in learning and diving into NodeJS",
            button: "Join the community",
        },
        section_about: {
            title: "We are a community of devs for devs",
            card_1_title: "+4,000 MEMBERS ON MEETUP",
            card_1_text: "We are always there promoting online and offline events",
            card_2_title: "NODEJS COURSE FOR BEGINNERS",
            card_2_text: "One of the largest online courses that has helped thousands of devs enter the market",
            card_3_title: "FROM BASIC TO ADVANCED NODEJS",
            card_3_text: "Delivered for free to the community",
        },
        section_support: {
            title: "Companies that have supported the community over the years",
            button: "Support us with your company",
        },
        section_voluntary: {
            title: "Join the community",
            button_1: "Become a volunteer",
            button_2: "Partnerships",
        },
        section_team: {
            title: "Team",
        },
        section_social: {
            title: "Social media",
        },
        section_upcoming_events: {
            title: "Upcoming events",
        },
        section_upcoming_lives: {
            title: "Upcoming lives",
        },
    }
}