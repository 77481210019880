import { Col, Row } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { UpcomingLivesData } from '../../../utils/data';

export const UpcomingLivesComponent = () => {
    const [translate] = useOutletContext() as any;
    const [data, setData] = useState<any>([]);

    const getData = async () => {
        // await axios.get('https://www.googleapis.com/youtube/v3/search', {
        //     params: {
        //         part: 'snippet',
        //         channelId: 'UCd4Cp-rzdSAze6C-FOFQ3aw',
        //         type: 'video',
        //         eventType: 'upcoming',
        //         key: 'AIzaSyD23v4u58hj5vdHM-sPo8H7rv2McVAqu_Q'
        //     }
        // }).then((response: any) => {
        //     setData(response.data.items)
        // }).catch(() => {
        //     setData(UpcomingLivesData.items)
        // });
        setData(UpcomingLivesData.items)
    }

    useEffect(() => {
        getData()
    }, []);

    return (<>
        {
            (data.length > 0) && <div id="section_upcoming_lives" className="site-content padding-bottom-large bg-primary">
                <div className="text-ovesrlay">
                    <Row gutter={[16, 16]} justify={"center"}>
                        <Col xs={24} sm={18}>
                            <Row gutter={[16, 16]} className='section-services' justify={'center'}>
                                <Col span={24} className='services-title'>
                                    <div className='center-content'>
                                        {translate.t("section_upcoming_lives.title")}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[16, 16]} justify={'center'}>
                                        {
                                            data.map((item: any, index: number) => (
                                                <Col xs={24} lg={8} key={index}>
                                                    <iframe
                                                        className="video-iframe"
                                                        src={"https://www.youtube.com/embed/" + item.id.videoId}
                                                        title={item.snippet.title}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowFullScreen
                                                    ></iframe>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        }
    </>)
}