export enum StorageKeys {
    language = 'language',
}

export const StorageService = {
    get: (key: StorageKeys) => {
        return (typeof window !== 'undefined') ? window.localStorage.getItem(key) : false;
    },
    getObject: (key: StorageKeys) => {
        return JSON.parse(StorageService.get(key) || "{}");
    },
    save: (key: StorageKeys, data: any) => {
        return (typeof window !== 'undefined') ? window.localStorage.setItem(key, data) : false;
    },
    delete: (key: StorageKeys) => {
        return (typeof window !== 'undefined') ? window.localStorage.removeItem(key) : false;
    }
}