import { useTranslation } from 'react-i18next'

import Flag from './flags'
import { StorageKeys, StorageService } from '../storage'
import { Dropdown, MenuProps, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'

export const I18n = () => {
  const { i18n } = useTranslation()

  const selectedLanguage = i18n.language

  const items: MenuProps['items'] = [
    {
      key: 'pt-BR',
      label: (
        <div className='i18n-language-option' onClick={() => handleChangeLanguage('pt-BR')}>
          <Flag
            image="/flags/brazil.svg"
            isSelected={selectedLanguage === 'pt-BR'}
          />
          <div className='i18n-language-label-option'>Português</div>
        </div>
      ),
    },
    {
      key: 'en-US',
      label: (
        <div className='i18n-language-option' onClick={() => handleChangeLanguage('en-US')}>
          <Flag
            image="/flags/usa.svg"
            isSelected={selectedLanguage === 'en-US'}
          />
          <div className='i18n-language-label-option'>English (US)</div>
        </div>
      ),
    },
  ];

  const [currentLanguague, setCurrentLanguague] = useState<any>({});

  function handleChangeLanguage(language: any) {

    i18n.changeLanguage(language)

    StorageService.save(StorageKeys.language, language)
  }

  useEffect(() => {
    const tempLang: any = items.find((item: any) => item.key === selectedLanguage)

    setCurrentLanguague(tempLang);
  }, [i18n.language]);


  return (
    <Dropdown menu={{ items }}>
      <div className='clickable'>
        {currentLanguague?.label}
      </div>
    </Dropdown>
  )
}