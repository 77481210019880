import { FaFacebookF, FaLinkedinIn, FaTwitter, FaMeetup } from 'react-icons/fa';
import { BsLinkedin, BsInstagram, BsGlobe, BsYoutube, BsFacebook } from 'react-icons/bs';
import { TfiYoutube } from 'react-icons/tfi';

export const CardHomeData = [
    {
        title: "card_1_title",
        img: "/photos/people.jpg",
        text: "card_1_text",
    },
    {
        title: "card_2_title",
        img: "/photos/people-2.jpg",
        text: "card_2_text",
    },
    {
        title: "card_3_title",
        img: "/photos/people-3.jpg",
        text: "card_3_text",
    }
]

export const PartnersData = [
    {
        img: "/photos/companies/google.png",
        name: "google",
    },
    {
        img: "/photos/companies/pagarme.png",
        name: "pagarme",
    },
    {
        img: "/photos/companies/lambda3.jpg",
        name: "lambda3",
    },
    {
        img: "/photos/companies/loft.jpg",
        name: "loft",
    },
    {
        img: "/photos/companies/pravaler.png",
        name: "pravaler",
    },
    {
        img: "/photos/companies/caelum.png",
        name: "caelum",
    },
    {
        img: "/photos/companies/flash.png",
        name: "Flash Benefícios",
    }
];

export const TeamData = [
    {
        name: "ERICK WENDEL",
        img: "/photos/team/erick-wendel.jpg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/erickwendel/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/erickwendel_/'
            },
            {
                label: 'site',
                icon: <BsGlobe />,
                link: 'https://linktr.ee/erickwendel'
            }
        ]
    },
    {
        name: "Ana neri",
        img: "/photos/team/ana-neri.jpg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/ananeridev/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/ananeridev/'
            },
            {
                label: 'site',
                icon: <BsGlobe />,
                link: 'https://beacons.ai/ananeridev'
            }
        ]
    },
    {
        name: "Lucas Santos",
        img: "/photos/team/lucas.jpg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/lsantosdev/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/lsantosdev/'
            },
            {
                label: 'site',
                icon: <BsGlobe />,
                link: 'https://lsantos.dev/'
            }
        ]
    },
    {
        name: "Monica Craveiro de Menezes",
        img: "/photos/team/monica.jpg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/mocraveirodev/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/mocraveirodev/'
            },
            {
                label: 'site',
                icon: <BsGlobe />,
                link: 'https://5tr.in/mocraveirodev/'
            }
        ]
    },
    {
        name: "Mayumi Shingaki",
        img: "/photos/team/mayumi.jpg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/mayumi-shingaki/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/mayumidev/'
            },
            {
                label: 'site',
                icon: <BsGlobe />,
                link: 'https://linktr.ee/mayumidev'
            }
        ]
    },
    {
        name: "Natiely Alves",
        img: "/photos/team/natiely.jpeg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/natiely-schmitt-8b231a275/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/naah_schmitt/'
            },
        ]
    },
    {
        name: "Denis Velrino",
        img: "/photos/team/velrino.jpeg",
        text: "Estamos sempre por lá divulgando eventos online e presencial",
        social: [
            {
                label: 'linkedin',
                icon: <BsLinkedin />,
                link: 'https://www.linkedin.com/in/velrino/'
            },
            {
                label: 'instagram',
                icon: <BsInstagram />,
                link: 'https://www.instagram.com/velrino/'
            },
            {
                label: 'site',
                icon: <BsGlobe />,
                link: 'https://velrino.github.io/'
            }
        ]
    },
]

export const SocialData = [
    {
        label: 'Guild',
        icon: <img src="/photos/helpers/guild-host.png" className='social-image-icon' />,
        link: 'https://guild.host/nodebr/'
    },
    {
        label: 'linkedin',
        icon: <FaLinkedinIn />,
        link: 'https://www.linkedin.com/company/nodebr/'
    },
    {
        label: 'instagram',
        icon: <BsInstagram />,
        link: 'https://www.instagram.com/node_br/'
    },
    {
        label: 'youTube',
        icon: <TfiYoutube />,
        link: 'https://www.youtube.com/@NodeBRjs'
    },
    {
        label: 'twitter',
        icon: <FaTwitter />,
        link: 'https://twitter.com/nodebr'
    },
    {
        label: 'facebook',
        icon: <FaFacebookF />,
        link: 'https://www.facebook.com/brnode'
    },
]

export const MenuData = [
    {
        label: 'menu.home',
        link: 'section_home',
    },
    {
        label: 'menu.about',
        link: 'section_about',
    },
    {
        label: 'menu.support_us',
        link: 'section_support',
    },
    {
        label: 'menu.join_us',
        link: 'section_voluntary',
    },
    {
        label: 'menu.upcoming_events',
        link: 'section_upcoming_events',
    },
    {
        label: 'menu.upcoming_lives',
        link: 'section_upcoming_lives',
    },
    {
        label: 'menu.team',
        link: 'section_team',
    },
    {
        label: 'menu.social_network',
        link: 'section_social',
    }
]

export const UpcomingLivesData = {
    "kind": "youtube#searchListResponse",
    "etag": "pYZBGiv5e6AhW_ifMvIpz2IUoso",
    "regionCode": "BR",
    "pageInfo": {
        "totalResults": 2,
        "resultsPerPage": 2
    },
    "items": [
        {
            "kind": "youtube#searchResult",
            "etag": "EwAg-1WTClrvBQjacVKCrVk8aRs",
            "id": {
                "kind": "youtube#video",
                "videoId": "xHBj5Y8cxzg"
            },
            "snippet": {
                "publishedAt": "2023-06-20T23:57:33Z",
                "channelId": "UCd4Cp-rzdSAze6C-FOFQ3aw",
                "title": "[▶️LIVE NODEBR] - Abrindo a Caixa de Mágica: Event Loop",
                "description": "No dia-a-dia utilizamos diversas tecnologias e ferramentas que são como caixas de mágica, funcionam, mas não fazemos ideia ...",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/xHBj5Y8cxzg/default_live.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/xHBj5Y8cxzg/mqdefault_live.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/xHBj5Y8cxzg/hqdefault_live.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "NodeBR",
                "liveBroadcastContent": "upcoming",
                "publishTime": "2023-06-20T23:57:33Z"
            }
        },
        {
            "kind": "youtube#searchResult",
            "etag": "_H3g0msMe8NtrEVXui5EGQMZ7AI",
            "id": {
                "kind": "youtube#video",
                "videoId": "j40xKKIXbWQ"
            },
            "snippet": {
                "publishedAt": "2023-06-21T00:21:41Z",
                "channelId": "UCd4Cp-rzdSAze6C-FOFQ3aw",
                "title": "NodeBR #63 - The Fun Part Of NodeJS",
                "description": "Encontro presencial em São Paulo Estaremos junto da empresa Flash Benefícios num encontro maneirissímos para falar sobre ...",
                "thumbnails": {
                    "default": {
                        "url": "https://i.ytimg.com/vi/j40xKKIXbWQ/default_live.jpg",
                        "width": 120,
                        "height": 90
                    },
                    "medium": {
                        "url": "https://i.ytimg.com/vi/j40xKKIXbWQ/mqdefault_live.jpg",
                        "width": 320,
                        "height": 180
                    },
                    "high": {
                        "url": "https://i.ytimg.com/vi/j40xKKIXbWQ/hqdefault_live.jpg",
                        "width": 480,
                        "height": 360
                    }
                },
                "channelTitle": "NodeBR",
                "liveBroadcastContent": "upcoming",
                "publishTime": "2023-06-21T00:21:41Z"
            }
        }
    ]
}